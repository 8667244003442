<template>
  <div class="mb-2">
    <div class="preview_audio_video">
      <div v-if="!section.url" class="default_audio_video">
        <i class="fas fa-volume-up d-flex align-items-center justify-content-center"></i>
      </div>

      <iframe v-else :height="section.height_enabled ? section.height : '199px'"
              :class="{'apply_radius_to_iframe':!section.title}" class="iframe" allowtransparency="true" scrolling="no"
              frameborder="no" :src="section.url"></iframe>

      <div v-if="section.title" :style="bioSectionThemeStyleBinding('background',getBioLinkAdds)" class="d-block title">
        <p :style="bioSectionThemeStyleBinding('text',getBioLinkAdds)">{{ section.title }}</p></div>
    </div>
  </div>

</template>
<script>


export default ({
  methods: {},
  props: [
    'isPreview',
    'section',
    'getBioLinkAdds'
  ]
})
</script>
